export const statuses = {
  authorized: 'Autorizado',
  pending: 'Pendente',
  rejected: 'Rejeitado',
  inactive: 'Inativo',
};

export function getName(value) {
  return statuses[value];
}
