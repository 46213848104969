<template>
  <div class="page-container forward-page">
    <portal to="page-name">Encaminhamento</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">Encaminhamento
          <span v-if="form.code"> - </span>
          <small class="text-secondary"> {{ form.code }}</small>
        </div>
      </div>
      <div class="card-body">
        <div v-if="authorized">
          <div class="resume">
            <div class="stenci-title">Dados da guia</div>
            <div class="columns">
              <div class="column col-4 col-sm-12 form-group">
                <label class="form-label text-bold">Data do atendimento</label>
                {{ form.createdAt | date('DD/MM/YYYY HH:mm') }}
              </div>
              <div class="column col-4 col-sm-12 form-group">
                <label class="form-label text-bold">Status</label>
                <span class="text-secondary text-bold">
                  {{ status.getName(form.status) }}
                </span>
              </div>
              <div class="column col-4 col-sm-12 form-group">
                <label class="form-label text-bold">Responsável</label>
                {{ form.collaborator ? form.collaborator.name : '-' }}
              </div>
              <div class="column col-12 form-group" v-if="form.notes">
                <label class="form-label text-bold">Observações</label>
                {{ form.notes }}
              </div>
            </div>
            <div class="divider" />
            <div class="stenci-title">Procedimentos</div>
            <table class="table table-hover">
              <thead>
              <tr>
                <th class="hide-sm" width="50px">#</th>
                <th>Procedimento</th>
                <th class="hide-sm">Parceiro</th>
                <th class="text-center" width="20px">Quantidade</th>
                <th class="text-right" width="100px">Valor</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(expense, i) in form.expenses" :key="i">
                <td class="hide-sm">{{ i + 1 }}</td>
                <td>
                  {{ expense.code | tuss }} - {{ expense.name }}
                  <span v-if="expense.specialty"> ({{ expense.specialty.name }})</span>
                </td>
                <td class="hide-sm">
                  {{ expense.partner.name }}
                </td>
                <td class="text-center">x{{ expense.quantity }}</td>
                <td class="text-right">{{ expense.final | currency }}</td>
              </tr>
              </tbody>
            </table>
            <div class="float-right mr-2 mt-1 text-bold">{{ total | currency }}</div>
            <div class="stenci-title mt-2">Informações financeiras</div>
            <div v-if="form.courtesy">
              <div>Cortesia</div>
            </div>
            <table class="table table-hover" v-else>
              <thead>
              <tr>
                <th width="50px">#</th>
                <th>Vencimento</th>
                <th>Conta</th>
                <th>Categoria</th>
                <th>Forma de pagamento</th>
                <th class="text-right">Valor</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(installment, i) in form.installments" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ installment.dueDate | date }}</td>
                <td>{{ installment.bankAccount ? installment.bankAccount.name : '-' }}</td>
                <td>{{ installment.category ? installment.category.name : '-' }}</td>
                <td>{{ installment.method ? installment.method.name : '-' }}</td>
                <td class="text-right">{{ installment.value | currency }}</td>
              </tr>
              </tbody>
            </table>
            <div class="float-right mr-2 mt-1 text-bold">{{ payed | currency }}</div>
          </div>
        </div>
        <div v-else>
          <div class="stenci-title">Dados principais</div>
          <div class="columns form-group">
            <div class="column col-3 col-md-5 col-sm-12">
              <label for="status" class="form-label">Status</label>
              <select id="status" name="status" class="form-select"
                      v-model="form.status">
                <option v-for="(text, value) in status.statuses" :value="value" :key="value">
                  {{ text }}
                </option>
              </select>
            </div>
          </div>
          <div class="columns">
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.name.$error}">
              <label class="form-label">Nome do paciente</label>
              <dx-autocomplete
                id="patient-name"
                v-model="patient"
                :source="findPatient"
                label="name"
                track-by="id"
                :readonly="!!form.patient.id"
                @select="setPatient"
                @blur="onPatientBlur"
                :debounce="800"
                placeholder="Nome do paciente, data de nascimento ou CPF">
                <template slot="action">
                  <button
                    @click="unsetPatient"
                    class="btn btn-action input-group-btn btn-icon"
                    :class="patient ? 'btn-gray' : 'btn-neutral'"
                    tabindex="-1">
                    <fa-icon :icon="['fal', patient ? 'times' : 'search']"></fa-icon>
                  </button>
                </template>
                <template v-slot="{ item }">
                  <div>{{ item.name }}</div>
                  <small class="text-primary" v-if="item.id === null">
                    (paciente sem cadastro)
                  </small>
                  <small class="text-primary" v-else>
                    <fa-icon :icon="['fal', 'gift']"></fa-icon>
                    {{ item.birthDate | date }} ({{ item.birthDate | dateOld }})
                  </small>
                </template>
              </dx-autocomplete>
              <template v-if="$v.form.patient.name.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.name.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.birthDate.$error}">
              <label class="form-label">Nascimento</label>
              <dx-input-date
                id="appointment-patient-birth-date"
                class="form-input"
                v-model="form.patient.birthDate"
                :readonly="!!form.patient.id"
                @blur="$v.form.patient.birthDate.$touch()"
              />
              <template v-if="$v.form.patient.birthDate.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.birthDate.required">Campo obrigatório</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.birthDate.date">Data inválida</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.birthDate.maxDate">
                  Data não pode ser superior a hoje
                </div>
              </template>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.gender.$error}">
              <label class="form-label">Sexo</label>
              <select id="gender" name="gender"
                      v-model="form.patient.gender"
                      :disabled="!!form.patient.id"
                      class="form-select" @change="$v.form.patient.gender.$touch()">
                <option value="">[Selecione]</option>
                <option value="female">Feminino</option>
                <option value="male">Masculino</option>
              </select>
              <template v-if="$v.form.patient.gender.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.gender.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-4 col-sm-12 form-group">
              <label for="cpf" class="form-label">CPF</label>
              <input type="text" id="cpf" name="cpf"
                     v-model="form.patient.identity.value"
                     @change="loadEntityPatient"
                     class="form-input" autocomplete="nope"
                     placeholder="000.000.000-00" v-mask-cpf>
            </div>
            <div class="column col-4 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.phone.$error}">
              <label for="phone" class="form-label">Telefone</label>
              <input type="text" id="phone" name="phone" v-model="form.patient.phone"
                     class="form-input" maxlength="14" @blur="$v.form.patient.phone.$touch()"
                     placeholder="(00) 0000-0000" autocomplete="nope" v-mask-phone.br>
              <template v-if="$v.form.patient.phone.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.phone.phone">Telefone inválido</div>
              </template>
            </div>
            <div class="column col-4 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.cellphone.$error}">
              <label for="cellphone" class="form-label">Celular</label>
              <input type="text" id="cellphone" name="cellphone"
                     @blur="$v.form.patient.cellphone.$touch()"
                     v-model="form.patient.cellphone" class="form-input" autocomplete="nope"
                     placeholder="(00) 0 0000-0000" v-mask-phone.br>
              <template v-if="$v.form.patient.cellphone.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.cellphone.phone">Telefone inválido</div>
              </template>
            </div>
          </div>
          <div class="divider" />
          <div class="stenci-title">Procedimentos / exames</div>
          <div class="columns">
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.expenses.$error && !(partner && partner.id)}">
              <label class="form-label">Parceiro</label>
              <dx-autocomplete
                id="partner-name"
                v-model="partner"
                :source="findPartner"
                label="name"
                track-by="id"
                :readonly="!!(partner && partner.id)"
                @select="setPartner"
                :debounce="800"
                placeholder="Nome do parceiro">
                <template slot="action">
                  <button
                    v-if="partner && partner.id"
                    @click="unsetPartner"
                    class="btn btn-action input-group-btn btn-icon btn-gray"
                    tabindex="-1">
                    <fa-icon :icon="['fal', 'times']" />
                  </button>
                  <button
                    v-else
                    class="btn btn-action input-group-btn btn-icon btn-neutral"
                    tabindex="-1">
                    <fa-icon :icon="['fal', 'search']" />
                  </button>
                </template>
                <template v-slot="{ item }">
                  <div>{{ item.name }}</div>
                  <small class="text-primary" v-if="item.id === null">
                    (parceiro não cadastrado)
                  </small>
                </template>
              </dx-autocomplete>
              <template v-if="$v.form.expenses.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.expenses.required && !(partner && partner.id)">
                  Informe um parceiro
                </div>
              </template>
            </div>
            <div class="column col-12 form-group"
                 :class="{'has-error': $v.form.expenses.$error}">
              <label class="form-label">Procedimento</label>
              <dx-autocomplete
                id="expense-name"
                label="name"
                track-by="id"
                v-model="expense"
                :source="loadExpense"
                :custom-label="setExpenseCustomName"
                :disabled="!(partner && partner.id) || cashierClosed
                  || form.installments.length > 0"
                @select="onSelectExpense"
                :debounce="800"
                placeholder="Código ou nome do procedimento / exame">
                <template slot="action">
                  <button class="btn btn-neutral btn-action btn-icon input-group-btn"
                          :class="{loading: expenseModal.loading}"
                          :disabled="expenseModal.loading || !(partner && partner.id)
                            || cashierClosed || form.installments.length > 0"
                          @click="openExpenseModal">
                    <fa-icon :icon="['fal', 'search']"/>
                  </button>
                </template>
              </dx-autocomplete>
              <template v-if="$v.form.expenses.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.expenses.required">Informe um procedimento</div>
              </template>
            </div>
          </div>
          <div class="empty mt-2" v-if="form.expenses.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Nenhum procedimento adicionado</p>
            <p class="empty-subtitle">
              Selecione um convênio e informe parte do nome ou código do procedimento
            </p>
          </div>
          <table class="table table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" width="50px">#</th>
              <th>Procedimento</th>
              <th class="hide-sm">Parceiro</th>
              <th class="text-center" width="110px">Quantidade</th>
              <th class="text-right" width="100px">Valor</th>
              <th width="50px"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(expense, i) in form.expenses" :key="i">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td>
                {{ expense.code | tuss }} - {{ expense.name }}
                <span v-if="expense.specialty"> ({{ expense.specialty.name }})</span>
              </td>
              <td class="hide-sm">
                {{ expense.partner.name }}
              </td>
              <td class="text-center">
                <span v-if="expenseModal.loading || cashierClosed || form.installments.length > 0">
                  x{{expense.quantity}}
                </span>
                <st-input-number-step v-else
                                      v-model="expense.quantity"
                                      :min-value="1"
                                      :max-value="20"
                />
              </td>
              <td class="text-right">{{ expense.quantity * expense.unitValue | currency }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-error tooltip"
                        data-tooltip="Excluir" @click="removeExpense(i)"
                        :disabled="cashierClosed">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="payment-methods" v-if="form.expenses.length > 0">
            <div class="columns">
              <div class="column col-6">
                <label class="form-switch">
                  <input type="checkbox" @change="resetPayment" v-model="showPayment"
                         :disabled="cashierClosed">
                  <i class="form-icon"></i>Registrar cobrança
                </label>
              </div>
              <div class="column col-6">
                <label class="float-right text-bold">{{ total | currency }}</label>
              </div>
            </div>
            <div v-if="showPayment && this.payment.cashiers.length > 0">
              <div class="divider" />
              <div class="stenci-title">Informações financeiras</div>
              <div class="columns">
                <div class="column col-4 col-sm-12 form-group"
                     :class="{'has-error': $v.payment.cashier.id.$error}">
                  <label class="form-label" for="bank-account">Caixa</label>
                  <select id="bank-account" v-model="payment.cashier.id" class="form-select"
                          :disabled="cashierClosed"
                          @focus="$v.payment.cashier.id.$touch()"
                          @change="changeCashier">
                    <option value="">[Selecione]</option>
                    <option v-for="item in payment.cashiers" :value="item.id" :key="item.id">
                      {{ item.bankAccount.name }}
                    </option>
                  </select>
                </div>
                <div class="column col-5 col-sm-12 form-group"
                     :class="{'has-error': $v.payment.cashier.category.id.$error}">
                  <label class="form-label" for="cashier-category">Categoria</label>
                  <select id="cashier-category" v-model="payment.cashier.category.id"
                          class="form-select"
                          @change="$v.payment.cashier.category.id.$touch()"
                          :disabled="!payment.cashier.id">
                    <option value="">[Selecione]</option>
                    <option v-for="item in payment.categories" :value="item.id" :key="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="columns">
                <div class="column col-2 col-md-4 col-sm-12" v-if="!form.courtesy">
                  <label class="form-label">Total (R$)</label>
                  <dx-input-number id="value" :value="total"
                                   class="form-input text-right"
                                   :disabled="true" :precision="2" maxlength="7"/>
                </div>

                <div class="column col-2 col-sm-12 form-group" style="margin-top: 3px"
                     :class="{'has-error': $v.payment.discount.value.$error}">
                  <label>Desconto</label>
                  <div class="input-group">
                    <select class="form-select" v-model="payment.discount.type"
                            style="max-width: 2.5rem; background-color: #f8f9fa"
                            :disabled="form.installments.length > 0 || form.courtesy">
                      <option value="percent">%</option>
                      <option value="value">R$</option>
                    </select>
                    <dx-input-number id="discount-percent"
                                     class="form-input text-right"
                                     @change="calculateDiscountPercentValue"
                                     :precision="2" maxlength="6"
                                     :disabled="form.installments.length > 0 || form.courtesy"
                                     v-model="payment.discount.percent"
                                     v-if="payment.discount.type === 'percent'" />
                    <dx-input-number id="discount-value"
                                     class="form-input text-right"
                                     @focus="$v.payment.discount.value.$touch()"
                                     @change="calculateDiscountPercentValue"
                                     :precision="2" maxlength="10"
                                     v-model="payment.discount.value"
                                     :disabled="form.installments.length > 0 || form.courtesy
                               || payment.discount.type === 'percent'" />
                  </div>
                </div>
                <div class="column col-2 col-sm-12 form-group" style="margin-top: 3px">
                  <label>Juros</label>
                  <div class="input-group">
                    <select class="form-select" v-model="payment.interest.type"
                            style="max-width: 2.5rem; background-color: #f8f9fa"
                            :disabled="form.installments.length > 0 || form.courtesy">
                      <option value="percent">%</option>
                      <option value="value">R$</option>
                    </select>
                    <dx-input-number id="interest-percent"
                                     class="form-input text-right"
                                     @change="calculateInterestPercentValue"
                                     :precision="2" maxlength="6"
                                     :disabled="form.installments.length > 0 || form.courtesy"
                                     v-model="payment.interest.percent"
                                     v-if="payment.interest.type === 'percent'" />
                    <dx-input-number id="interest-value"
                                     class="form-input text-right"
                                     @change="calculateInterestPercentValue"
                                     :precision="2" maxlength="10"
                                     v-model="payment.interest.value"
                                     :disabled="form.installments.length > 0 || form.courtesy
                               || payment.interest.type === 'percent'" />
                  </div>
                </div>

                <div class="column col-2 col-md-4 col-sm-12" v-if="!form.courtesy">
                  <label class="form-label">Total líquido (R$)</label>
                  <dx-input-number id="value"
                                   :value="total - payment.discount.value + payment.interest.value"
                                   class="form-input text-right"
                                   :disabled="true" :precision="2" maxlength="7"/>
                </div>
                <div class="column col-2 col-md-4 col-sm-12" v-if="!form.courtesy">
                  <label class="form-label">Taxa (R$)</label>
                  <dx-input-number id="value" v-model="totalRate"
                                   class="form-input text-right"
                                   :disabled="true" :precision="2" maxlength="7"/>
                </div>
              </div>
              <div class="columns">
                <div class="column col-2 col-md-4 col-sm-12">
                  <label for="partial" class="form-label">Saldo a pagar (R$)</label>
                  <dx-input-number id="partial" :value="form.courtesy ? 0 : totalOpened"
                                   class="form-input text-right"
                                   :class="totalOpened < 0 ? 'text-error' : ''"
                                   :disabled="true" :precision="2" maxlength="7"/>
                </div>
                <div class="column col-4"
                     :class="{'has-error': $v.payment.methodId.$error}"
                     v-if="!form.courtesy">
                  <label class="form-label" for="method">Forma de pagamento</label>
                  <select id="method" v-model="payment.methodId" class="form-select"
                          @change="changePaymentMethod"
                          @blur="$v.payment.methodId.$touch()">
                    <option value="">[Selecione]</option>
                    <option v-for="item in payment.methods" :value="item.id" :key="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="column col-2 form-group" :class="{'has-error': $v.payment.date.$error}">
                  <label class="form-label" for="payment-date">Vencimento</label>
                  <dx-input-date
                    id="payment-date"
                    class="form-input text-center"
                    v-model="payment.date"
                    @blur="$v.payment.date.$touch()"
                  />
                </div>
                <div class="column col-1 col-md-3 col-sm-12"
                     v-if="enableInstallment && !form.courtesy">
                  <label class="form-label" for="method">Qtde.</label>
                  <select id="quantity" v-model="payment.installment"
                          class="form-select">
                    <option v-for="(item, i) in payment.installments"
                            :value="item.installment" :key="i">
                      {{ item.installment }}
                    </option>
                  </select>
                </div>
                <div class="column col-2 col-md-4 col-sm-12"
                     :class="{'has-error': $v.payment.value.$error}"
                     v-if="!form.courtesy">
                  <label class="form-label">Valor (R$)</label>
                  <dx-input-number id="value" v-model="payment.value"
                                   @focus="payment.value = ((payment.value || 0) === 0
                                     ? totalOpened
                                     : payment.value)"
                                   @blur="$v.payment.value.$touch()"
                                   class="form-input text-right"
                                   :precision="2" maxlength="7"/>
                </div>
                <div class="column col-auto" v-if="!form.courtesy">
                  <button class="btn btn-primary btn-block btn-icon" style="margin-top: 22px;"
                          :disabled="totalOpened <= 0"
                          @click="addInstallment">
                    <fa-icon :icon="['fal', 'plus-circle']" />
                  </button>
                </div>
              </div>
              <div class="payment-body" v-if="!form.courtesy">
                <div class="empty" v-if="form.installments.length === 0">
                  <div class="empty-icon">
                    <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
                  </div>
                  <div class="empty-subtitle">
                    Forma de pagamento não informada.
                  </div>
                </div>
                <table class="table table-hover" v-else>
                  <thead>
                  <tr>
                    <th width="50px">#</th>
                    <th>Forma de pagamento</th>
                    <th class="text-center">Vencimento</th>
                    <th class="text-right">Desconto (R$)</th>
                    <th class="text-right">Juro (R$)</th>
                    <th class="text-right">Taxa (R$)</th>
                    <th class="text-right">Valor (R$)</th>
                    <th width="50px"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(installment, i) in form.installments" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>
                      {{ installment.method.name }}
                      <small v-if="installment.method.type === 'credit_card'">
                        ({{ installment.method.installment.current }})
                      </small>
                    </td>
                    <td class="text-center">{{ installment.dueDate | date }}</td>
                    <td class="text-right">{{ installment.discount | currency }}</td>
                    <td class="text-right">{{ installment.interest | currency }}</td>
                    <td class="text-right">{{ installment.rate | currency }}</td>
                    <td class="text-right">{{ installment.value | currency }}</td>
                    <td class="text-right">
                      <button slot="action" @click="removeInstallment(installment)"
                              :disabled="cashierClosed"
                              class="btn btn-error btn-action btn-icon btn-sm">
                        <fa-icon :icon="['fal', 'times']"/>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else-if="showPayment && this.payment.cashiers.length === 0" class="columns">
              <div class="column">
                <div class="empty">
                  <div class="empty-icon">
                    <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
                  </div>
                  <p class="empty-title h5">Nenhum caixa aberto</p>
                  <p class="empty-subtitle">
                    Para realizar a abertura do caixa selecione a opção "Abertura" do menu
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary btn-icon-left mr-1"
                v-if="!authorized"
                :class="{loading: saving}"
                :disabled="saving || finishing"
                @click="save(false)">
          <fa-icon :icon="['fal', 'save']"/>Salvar
        </button>
        <button class="btn btn-warning btn-icon-left mr-1"
                v-if="!authorized"
                :class="{loading: finishing}"
                :disabled="saving || finishing"
                @click="finish(true)">
          <fa-icon :icon="['fal', 'check-circle']"/>Finalizar
        </button>
        <button class="btn btn-gray mr-1"
                v-if="authorized"
                @click="print"
                :disabled="printing"
                :class="{loading: printing}">
          Imprimir guia
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
    <dx-modal title="Lista de procedimentos"
              v-model="expenseModal.show" size="lg"
              v-if="expenseModal.show"
              id="modal-forward-expenses">
      <div class="empty mt-2" v-if="expenseModal.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <p class="empty-title h6">Procedimentos</p>
        <p class="empty-subtitle">
          Nenhum procedimento encontrado. Verifique as configurações.
        </p>
      </div>
      <table class="table table-hover expense-modal-table" v-else>
        <thead>
        <tr>
          <th>Procedimento</th>
          <th>Profissional</th>
          <th class="text-right">Valor</th>
        </tr>
        </thead>
        <tbody>
        <tr class="c-hand" v-for="(item, i) in expenseModal.items" :key="i"
            @click="selectExpense(item)">
          <td><span>{{ item.code | tuss }} - </span>{{ item.name }}</td>
          <td>
            {{ item.professional.name }}
            <span v-if="item.professional.specialty">
              ({{ item.professional.specialty.name }})
            </span>
          </td>
          <td class="text-right">{{ item.price.final | currency }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="expenseModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { round } from 'lodash';
import {
  required,
  minValue,
  maxValue,
} from 'vuelidate/src/validators';
import { mergeFrom } from '@/helpers/object';
import currency from 'src/filters/currency';
import * as status from 'src/data/budget-statuses';
import { PRINT_ORDER, FINISH_ORDER } from '@/data/actions/modules/referral';
import formMixin from '../../../mixins/form';
import {
  date,
  phone,
  maxDate,
} from '../../../data/validators';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/forwards',
      isNew: false,
      loading: false,
      saving: false,
      finishing: false,
      authorized: false,
      showPayment: false,
      cashierClosed: false,
      printing: false,
      partner: null,
      patient: null,
      expense: null,
      expenses: [],
      status,
      expenseModal: {
        show: false,
        loading: false,
        items: [],
      },
      payment: {
        methods: [],
        cashiers: [],
        categories: [],
        installments: [],
        cashier: {
          id: '',
          bankAccount: {
            id: '',
          },
          category: {
            id: '',
          },
        },
        document: '',
        methodId: '',
        date: '',
        discount: {
          type: 'value',
          percent: 0,
          value: 0,
        },
        interest: {
          type: 'value',
          percent: 0,
          value: 0,
        },
        installment: 1,
        rate: 0,
        value: 0,
      },
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.loadMethods();
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
    this.getOpenCashiers();
    this.getCategories();
  },
  computed: {
    total() {
      return this.form.expenses
        .reduce((a, b) => a + Number(b.unitValue) * Number(b.quantity), 0);
    },
    payed() {
      return this.form.installments
        .reduce((a, b) => a + Number(b.value), 0);
    },
    totalOpened() {
      const installmentTotal = this.form.installments
        .reduce((a, b) => a + Number(b.value), 0);

      const value = round(this.total, 2) + round(this.payment.interest.value, 2)
        - round(this.payment.discount.value, 2) - round(installmentTotal, 2);

      return round(value, 2);
    },
    totalRate() {
      return this.form.installments.reduce((a, b) => a + Number(b.rate), 0);
    },
    enableInstallment() {
      if (!this.payment.methodId) {
        return false;
      }
      const found = this.payment.methods
        .find(({ id }) => id === this.payment.methodId);

      if (found && found.documentType.type === 'credit_card') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.payment.installments = found.installments;
        return true;
      }

      return false;
    },
    canAccessPrintForward() {
      if (PRINT_ORDER) {
        return this.$can(PRINT_ORDER);
      }
      return true;
    },
    canAccessFinishForward() {
      if (FINISH_ORDER) {
        return this.$can(FINISH_ORDER);
      }
      return true;
    },
  },
  validations() {
    const roles = {
      payment: {
        methodId: { required },
        date: { required, date },
        value: { required, minValue: minValue(0.01), maxValue: maxValue(this.totalOpened) },
        discount: {
          value: {},
        },
        cashier: {
          id: {},
          category: {
            id: '',
          },
        },
      },
      form: {
        patient: {
          name: { required },
          phone: { phone },
          birthDate: { date, required, maxDate: maxDate() },
          gender: { required },
          cellphone: { phone },
        },
        expenses: { required },
      },
    };

    if (this.showPayment) {
      roles.payment.cashier.id = { required };
      roles.payment.cashier.category.id = { required };
    }

    if ((this.payment.discount.value || 0) > 0) {
      roles.payment.discount.value = {
        maxValue: maxValue(this.total),
      };
    }

    return roles;
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          this.authorized = data.status === 'authorized';
          this.form = mergeFrom(this.blankForm(), data);

          this.form.expenses.forEach((expense) => {
            expense.unitValue = round(expense.final, 2);
            expense.cost *= expense.quantity;
            expense.final *= expense.quantity;
          });

          this.patient = data.patient;
          if (data.cashier) {
            this.payment.cashier = data.cashier;
          }
          this.showPayment = this.form.installments.length > 0;

          this.payment.discount.value = this.form.installments.reduce((a, b) => a + b.discount, 0);
          this.payment.interest.value = this.form.installments.reduce((a, b) => a + b.interest, 0);
          this.payment.rate = this.form.installments.reduce((a, b) => a + b.rate, 0);
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadMethods() {
      const params = {
        active: true,
        limit: 0,
        types: ['all', 'revenue'],
      };

      this.apiGetMethods(params)
        .then((data) => {
          this.payment.methods = data.items;
        })
        .catch(() => {})
        .then(() => {});
    },
    findPartner(search) {
      return this.$http.get(`/partners?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setPartner(data) {
      this.partner = {
        id: data.id,
        name: data.name,
        identity: data.identity ? data.identity : { type: 'cpf', value: '' },
        birthDate: data.birthDate,
        gender: data.gender,
        phone: data.phone,
        cellphone: data.cellphone,
        email: data.email,
      };
    },
    unsetPartner() {
      this.partner = null;
    },
    loadExpense(search) {
      const params = {
        partnerId: this.partner.id,
        search,
      };
      return this.apiGetExpenses(params);
    },
    findPartnerExpense() {
      const params = {
        partnerId: this.partner.id,
        active: true,
      };
      return this.apiGetExpenses(params)
        .then((data) => {
          this.expenseModal.items = data;
        });
    },
    apiGetExpenses(params) {
      return this.$http.get('/partner-expenses', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{
              id: null,
              name: 'Procedimento não encontrado',
            }];
          }
          const items = [];
          data.items.forEach((item) => {
            item.professionals.forEach((professional) => {
              items.push({
                id: item.expense.id,
                code: item.expense.tuss
                  ? item.expense.tuss.code
                  : '',
                name: item.expense.name,
                professional: {
                  id: professional.id,
                  name: professional.name,
                  council: professional.council,
                  specialty: professional.specialty,
                  age: professional.age,
                },
                price: professional.price,
              });
            });
          });
          return items;
        });
    },
    setExpenseCustomName(item) {
      let result = item.name;
      if (item.professional && item.professional.name) {
        result += ` com ${item.professional.name}`;
      }
      if (item.professional && item.professional.specialty && item.professional.specialty.name) {
        result += ` (${item.professional.specialty.name})`;
      }
      if (item.price && item.price.final) {
        result += ` por ${currency(item.price.final)})`;
      }
      return result;
    },
    onSelectExpense(item) {
      if (!item.id) {
        return;
      }
      const found = this.form.expenses
        .find(expense => expense.id === item.id
          && expense.partner.id === this.partner.id
          && expense.specialty.code === item.professional.specialty.code);
      if (found) {
        found.quantity += 1;
        found.final += item.price.final;
      } else {
        this.form.expenses.push({
          id: item.id,
          code: item.code,
          name: item.name,
          partner: {
            id: this.partner.id,
            name: this.partner.name,
          },
          professional: {
            id: item.professional.id,
            name: item.professional.name,
          },
          specialty: {
            code: item.professional.specialty.code,
            name: item.professional.specialty.name,
          },
          quantity: 1,
          unitValue: item.price.final,
          final: item.price.final,
        });
      }

      this.expense = null;
      this.expenses = [];
    },
    async openExpenseModal() {
      this.expenseModal.loading = true;
      this.expenseModal.items = [];
      await this.findPartnerExpense()
        .then((items) => {
          if (items) {
            this.expenseModal.items = items;
          }
        }).catch(() => {})
        .finally(() => {
          this.expenseModal.loading = false;
        });
      this.expenseModal.show = true;
    },
    selectExpense(item) {
      this.onSelectExpense(item);
      this.expenseModal.show = false;
    },
    removeExpense(i) {
      this.form.expenses.splice(i, 1);
    },
    clearPayment() {
      this.$v.payment.$reset();
      this.payment.methodId = '';
      this.payment.installment = 1;
      this.payment.date = '';
      this.payment.value = '';
    },
    addInstallment() {
      this.$v.payment.$touch();
      if (this.$v.payment.$error) {
        return;
      }
      let rateTotal = 0;
      let installmentTotal = 0;
      let dueDate = this.payment.date;
      const netValue = this.total - this.payment.discount.value + this.payment.interest.value;
      const method = this.payment.methods.find(({ id }) => id === this.payment.methodId);
      const rate = { percent: 0, total: 0 };
      const discount = { percent: 0, total: 0 };
      const interest = { percent: 0, total: 0 };
      if (method.installments && method.installments.length > 0) {
        method.installments.forEach((item) => {
          if (item.installment === this.payment.installment) {
            rate.percent = item.value / 100;
            rate.total = round(((item.value || 0) / 100) * this.payment.value, 2);
          }
        });
      }

      let installmentValue = round(this.payment.value / this.payment.installment, 2);
      let rateValue = round(rate.total / this.payment.installment, 2);
      discount.percent = netValue > 0 ? this.payment.discount.value / netValue : 0;
      interest.percent = netValue > 0 ? this.payment.interest.value / netValue : 0;
      let discountValue = round(
        (this.payment.value * discount.percent) / this.payment.installment,
        2,
      );
      let interestValue = round(
        this.payment.value * interest.percent / this.payment.installment,
        2,
      );

      let discountTotal = 0;
      let interestTotal = 0;
      if (this.totalOpened === 0) {
        discount.total = round(this.payment.discount.value, 2);
        interest.total = round(this.payment.interest.value, 2);
        discountTotal = this.payment.installments.reduce((a, b) => a + (b.discount || 0), 0);
        interestTotal = this.payment.installments.reduce((a, b) => a + (b.interest || 0), 0);
      } else {
        discount.total = round(this.payment.value * discount.percent, 2);
        interest.total = round(this.payment.value * interest.percent, 2);
      }

      for (let i = 1; i <= this.payment.installment; i += 1) {
        installmentTotal += installmentValue;
        rateTotal += rateValue;
        discountTotal += discountValue;
        interestTotal += interestValue;
        if (i === this.payment.installment && installmentTotal !== this.payment.value) {
          installmentValue += round(this.payment.value - installmentTotal, 2);
        }
        if (i === this.payment.installment && discountTotal !== discount.total) {
          discountValue += round(discount.total - discountTotal, 2);
        }
        if (i === this.payment.installment && rateValue !== rate.total) {
          rateValue += round(rate.total - rateTotal, 2);
        }
        if (i === this.payment.installment && interestValue !== interest.total) {
          interestValue += round(interest.total - interestTotal, 2);
        }

        if (method && method.compensation && i > 1) {
          if (method.compensation.type === 'after-day') {
            if (method.compensation.value === 30) {
              dueDate = moment(dueDate)
                .add(1, 'months')
                .format('YYYY-MM-DD');
            } else {
              dueDate = moment(dueDate)
                .add(method.compensation.value, 'days')
                .format('YYYY-MM-DD');
            }
          } else if (method.compensation.type === 'fixed-day') {
            dueDate = moment(dueDate)
              .set('date', method.compensation.value)
              .format('YYYY-MM-DD');
            if (moment(dueDate).diff(dueDate, 'days') < 0) {
              dueDate = moment(dueDate)
                .add(1, 'months')
                .format('YYYY-MM-DD');
            }
          }
          this.payment.date = dueDate;
        }

        this.form.installments.push({
          method: {
            id: method.id,
            name: method.name,
            type: method.documentType.type,
            compensation: method.compensation,
            installments: method.installments,
            installment: {
              total: this.payment.installment,
              current: i,
            },
          },
          dueDate,
          discount: discountValue,
          interest: interestValue,
          rate: rateValue,
          value: installmentValue,
        });
      }

      this.clearPayment();
    },
    removeInstallment(item) {
      const idx = this.form.installments.indexOf(item);
      this.form.installments.splice(idx, 1);
    },
    validate(finish = false) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return false;
      }

      if (this.form.expenses.length === 0) {
        this.$toast.show('Informe ao menos um procedimento', { type: 'error' });
        return false;
      }

      if (finish && !this.showPayment) {
        this.$toast.show('Obrigatório informar o método de pagamento!', { type: 'error' });
        return false;
      }

      if (this.showPayment && this.payment.cashiers.length === 0) {
        this.$toast.show('Para efetuar o recebimento é obrigatório efetuar a abertura do caixa!',
          { type: 'error' });
        return false;
      }

      if (this.showPayment && !this.payment.cashier.id) {
        this.$toast.show('Obrigatório informar o caixa!', { type: 'error' });
        return false;
      }

      if (this.showPayment && !this.payment.cashier.category.id) {
        this.$toast.show('Obrigatório informar a categoria!', { type: 'error' });
        this.showPayment = true;
        return false;
      }

      if (this.showPayment && this.totalOpened && !this.form.courtesy) {
        this.$toast.show('Existe saldo a pagar em aberto!', { type: 'error' });
        return false;
      }
      return true;
    },
    finish() {
      if (!this.canAccessFinishForward) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      if (!this.validate(true)) {
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente finalizar esse atendimento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.save(true);
              close();
            },
          },
        ],
      });
    },
    save(finish = false) {
      if (this.saving || this.finishing || !this.validate(finish)) {
        return;
      }

      if (finish) {
        this.finishing = true;
      } else {
        this.saving = true;
      }

      const data = this.clone(this.form);

      if (finish) {
        data.status = 'authorized';
      }

      if (this.form.courtesy) {
        delete data.installments;
      }

      this.apportionment();
      data.expenses = data.expenses.map(item => ({
        id: item.id,
        partnerId: item.partner.id,
        professionalId: item.professional.id,
        specialtyCode: item.specialty ? item.specialty.code : null,
        quantity: item.quantity,
        discount: item.discount,
        interest: item.interest,
        rate: item.rate,
        final: item.final,
      }));

      data.cashier = null;
      if (this.showPayment && this.payment.cashier.id) {
        const cashier = this.payment.cashiers
          .find(item => item.id === this.payment.cashier.id);
        const category = this.payment.categories
          .find(item => item.id === this.payment.cashier.category.id);

        data.cashier = {
          _id: this.payment.cashier.id,
          bankAccount: {
            _id: cashier.bankAccount.id,
            name: cashier.bankAccount.name,
          },
          category: {
            _id: category.id,
            name: category.name,
          },
        };
      }

      const request = this.isNew
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${this.form.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          this.form.installments = result.installments;
          if (this.isNew) {
            this.$router.replace(`${this.path}/${result.id}/edit`);
            this.isNew = false;
          }
          this.authorized = data.status === 'authorized';
          this.form.status = result.status;
          this.form.id = result.id;
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
          this.finishing = false;
        });
    },
    print() {
      if (!this.canAccessPrintForward) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return null;
      }
      if (!this.form.id) {
        return null;
      }

      this.printing = true;

      return this.$file
        .print(`/forwards/${this.form.id}/print`)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    findPatient(search) {
      return this.$http.get(`/patients/search?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setPatient(data) {
      this.patient = data;
      this.form.patient = {
        id: data.id,
        name: data.name,
        identity: data.identity ? data.identity : { type: 'cpf', value: '' },
        birthDate: data.birthDate,
        gender: data.gender,
        phone: data.phone,
        cellphone: data.cellphone,
        email: data.email,
      };
    },
    onPatientBlur(text) {
      this.form.patient.name = text;
      this.$v.form.patient.name.$touch();
    },
    unsetPatient() {
      this.patient = null;
      this.form.patient = this.blankPatientForm();
    },
    loadEntityPatient() {
      if (!this.form.patient.id) {
        return;
      }
      this.$http
        .get(`/entities/${this.form.patient.id}`)
        .then(({ data }) => {
          if (data && data.id) {
            this.patient = data;
            this.form.patient.id = data.id;
            this.form.patient.name = data.name;
            this.form.patient.birthDate = data.birthDate;
            this.form.patient.gender = data.gender;
            this.form.patient.phone = data.phone;
            this.form.patient.cellphone = data.cellphone;
            this.form.patient.email = data.email;
            if (data.identity && data.identity.value) {
              this.form.patient.identity = data.identity;
            }
          }
        })
        .catch(() => {});
    },
    apiGetMethods(params) {
      return this.$http.get('/payment-methods', { params })
        .then(({ data }) => data)
        .catch(() => {});
    },
    async getOpenCashiers() {
      let cashiers = [];
      await this.$http.get('/cashiers/accounts')
        .then(({ data }) => {
          cashiers = data.items.map(cashier => ({
            // eslint-disable-next-line no-underscore-dangle
            id: cashier.bankAccount._id,
            name: cashier.bankAccount.name,
            categories: cashier.categories,
          }));
        })
        .catch(() => {});

      const params = {
        limit: 0,
      };
      await this.$http.get('/cashiers?status=open', { params })
        .then(({ data }) => {
          data.items.forEach((openCashier) => {
            const cashier = cashiers
              .find(item => openCashier.bankAccount.id === item.id);
            if (cashier) {
              openCashier.categories = cashier.categories;
              openCashier.enabled = true;
            } else {
              openCashier.enabled = false;
            }
          });

          this.payment.cashiers = data.items
            .filter(openCashier => openCashier.enabled === true)
            .map(item => ({
              id: item.id,
              bankAccount: {
                id: item.bankAccount.id,
                name: item.bankAccount.name,
              },
              categories: item.categories,
            }));

          this.cashierClosed = false;
          if (this.payment.cashier.id) {
            const cashierSaved = this.payment.cashiers
              .filter(item => item.id === this.payment.cashier.id);
            if (cashierSaved.length === 0) {
              this.payment.cashiers.push({
                id: this.payment.cashier.id,
                bankAccount: {
                  id: this.payment.cashier.bankAccount.id,
                  name: this.payment.cashier.bankAccount.name,
                },
                categories: {
                  forward: {
                    _id: this.payment.cashier.category.id,
                    name: this.payment.cashier.category.name,
                  },
                },
              });
              this.cashierClosed = true;
            }
          }
        })
        .catch(() => {});

      if (!this.payment.cashier.id && this.payment.cashiers.length === 1) {
        this.payment.cashier.id = this.payment.cashiers[0].id;
        this.changeCashier();
      }
    },
    async getCategories() {
      const params = {
        active: true,
        limit: 0,
      };

      await this.$http.get('/categories', { params })
        .then(({ data }) => {
          if (data.items) {
            this.payment.categories = data.items.filter(item => item.type === 'revenue');
          }
        })
        .catch(() => {});
    },
    changeCashier() {
      if (this.payment.cashier.id) {
        const cashier = this.payment.cashiers.find(item => item.id === this.payment.cashier.id);
        this.payment.cashier.bankAccount.id = cashier.bankAccount.id;
        if (cashier.categories && cashier.categories.forward
          // eslint-disable-next-line no-underscore-dangle
          && cashier.categories.forward._id) {
          // eslint-disable-next-line no-underscore-dangle
          this.payment.cashier.category.id = cashier.categories.forward._id;
        }
      } else {
        this.payment.cashier.bankAccount.id = '';
        this.payment.cashier.category.id = '';
      }
    },
    changePaymentMethod() {
      let dueDate = moment().format('YYYY-MM-DD');

      const method = this.payment.methods.filter(item => item.id === this.payment.methodId).pop();
      const currentDate = moment().format('YYYY-MM-DD');
      if (method && method.compensation) {
        if (method.compensation.type === 'after-day') {
          dueDate = moment(currentDate)
            .add(method.compensation.value, 'days')
            .format('YYYY-MM-DD');
        } else if (method.compensation.type === 'fixed-day') {
          dueDate = moment(currentDate)
            .set('date', method.compensation.value)
            .format('YYYY-MM-DD');
          if (moment(dueDate).diff(currentDate, 'days') < 0) {
            dueDate = moment(dueDate)
              .add(1, 'months')
              .format('YYYY-MM-DD');
          }
        } else if (method.compensation.type === 'same-day') {
          dueDate = currentDate;
        }
        this.payment.date = dueDate;
      } else {
        this.payment.date = '';
      }
    },
    calculateDiscountPercentValue() {
      let discount = 0;
      if (this.payment.discount.type === 'percent') {
        if (this.payment.discount.percent && this.total > 0) {
          discount = Math.round(this.payment.discount.percent * this.total) / 100;
        }
        this.payment.discount.value = discount;
      } else {
        if (this.payment.discount.value && this.total > 0) {
          discount = Math.round(this.payment.discount.value / this.total * 10000) / 100;
        }
        this.payment.discount.percent = discount;
      }
    },
    calculateInterestPercentValue() {
      let interest = 0;
      if (this.payment.interest.type === 'percent') {
        if (this.payment.interest.percent && this.total > 0) {
          interest = Math.round(this.payment.interest.percent * this.total) / 100;
        }
        this.payment.interest.value = interest;
      } else {
        if (this.payment.interest.value && this.payment.total) {
          interest = Math.round(this.payment.interest.value / this.total * 10000) / 100;
        }
        this.payment.interest.percent = interest;
      }
    },
    resetPayment() {
      this.form.installments = [];
      this.payment.document = '';
      this.payment.methodId = '';
      this.payment.date = '';
      this.payment.discount.value = 0;
      this.payment.discount.percent = 0;
      this.payment.interest.value = 0;
      this.payment.interest.percent = 0;
      this.payment.installment = 1;
      this.payment.rate = 0;
      this.payment.value = 0;
    },
    apportionment() {
      this.form.expenses.forEach((expense) => {
        expense.discount = 0;
        expense.rate = 0;
        expense.interest = 0;
      });
      if (this.payment.discount.value > 0) this.discountApportionment();
      if (this.payment.interest.value > 0) this.interestApportionment();
      if (this.payment.rate > 0) this.rateApportionment();
    },
    discountApportionment() {
      let total = 0;
      const percent = this.payment.discount.value / this.total;
      this.form.expenses.forEach((expense) => {
        expense.discount = round(expense.final * percent, 2);
        total += expense.discount;
      });
      if (total !== this.payment.discount.value) {
        this.form.expenses[0].discount += this.payment.discount.value - total;
      }
    },
    interestApportionment() {
      let total = 0;
      const percent = this.payment.interest.value / this.total;
      this.form.expenses.forEach((expense) => {
        expense.interest = round(expense.final * percent, 2);
        total += expense.interest;
      });
      if (total !== this.payment.interest.value) {
        this.form.expenses[0].interest += this.payment.interest.value - total;
      }
    },
    rateApportionment() {
      let total = 0;
      const percent = this.payment.rate / (this.total - this.payment.discount.value
        + this.payment.interest.value);
      this.form.expenses.forEach((expense) => {
        expense.rate = round(expense.final * percent, 2);
        total += expense.rate;
      });
      if (total !== this.payment.rate) {
        this.form.expenses[0].rate += this.payment.rate - total;
      }
    },
    blankPatientForm() {
      return {
        id: '',
        identity: {
          type: 'cpf',
          value: '',
        },
        name: '',
        birthDate: '',
        gender: '',
        phone: '',
        cellphone: '',
        email: '',
      };
    },
    blankForm() {
      return {
        id: '',
        code: '',
        createdAt: '',
        status: 'pending',
        total: '',
        installments: [],
        courtesy: false,
        financial: true,
        collaborator: {
          id: '',
          name: '',
        },
        patient: this.blankPatientForm(),
        expenses: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.forward-page {
  .table {
    background-color: $gray-color-ultra-light;
    margin-top: $layout-spacing-sm;
  }
  .payment-methods {
    margin-top: $layout-spacing-lg;
  }
  .empty {
    margin-top: $layout-spacing-sm;
    padding: $layout-spacing-lg;
  }
}
</style>
