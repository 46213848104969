<template>
  <div class="page-container forwards-page">
    <portal to="page-name">Movimentações</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Movimentações de encaminhamento</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-3 col-sm-12 form-group">
            <label for="operation-type" class="form-label">Status</label>
            <select id="operation-type" name="operation-type" class="form-select"
                    v-model="filter.status">
              <option value="">[Todos os status]</option>
              <option v-for="(text, value) in status.statuses" :value="value" :key="value">
                {{ text }}
              </option>
            </select>
          </div>
          <div class="column form-group">
            <label for="search" class="form-label">Nome do paciente ou atendimento</label>
            <div class="input-group">
              <input type="text" id="search" class="form-input"
                     v-model="filter.search" maxlength="100" autocomplete="nope"
                     placeholder="Infome o nome do paciente ou número do atendimento">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" @click="load">
                <fa-icon :icon="['fal', loading ? 'sync' : 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto col-sm-12 mb-2" style="align-self: flex-end">
            <button class="btn btn-primary btn-icon btn-icon-left btn-block"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/> Novo encaminhamento
            </button>
          </div>
        </div>
        <div class="loading mt-2" v-if="loading"></div>
        <template v-else>
          <div class="empty mt-2" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Encaminhamentos</p>
            <p class="empty-subtitle">
              Nenhum encaminhamento encontrado. Verifique os filtros para realizar novas buscas
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" width="40px">#</th>
              <th>Data</th>
              <th>Status</th>
              <th>Paciente</th>
              <th>Número</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td class="hide-sm">{{ i + data.offset + 1 }}</td>
              <td width="130px">{{ item.createdAt | date('DD/MM/YYYY HH:mm') }}</td>
              <td class="hide-sm text-bold">
                <router-link :class="item.status === 'pending' ? 'text-warning' : 'text-info'"
                             :to="editLink(item)">
                  {{ status.getName(item.status) }}
                </router-link>
              </td>
              <td class="hide-sm">{{ item.patient.name }}</td>
              <td class="hide-sm">{{ item.code }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-gray mr-1"
                        :class="{loading: printing && idx === i}"
                        :disabled="(printing && idx === i) || item.status === 'pending'"
                        @click="print(item.id, i)">
                  <fa-icon :icon="['fal', 'print']"></fa-icon>
                </button>
                <button class="btn btn-sm btn-action btn-icon btn-error tooltip"
                        @click="deleteItem(item.id, i)" data-tooltip="Excluir">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer" v-if="data.items.length > 0">
        <st-pagination :total="data.total"
                       :limit="data.limit" @paginate="paginate"></st-pagination>
        <span>Total de registros:
          <span class="ml-2 text-primary text-bold">{{ data.total }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as status from 'src/data/budget-statuses';
import { PRINT_ORDER, DELETE_ORDER } from '@/data/actions/modules/referral';

export default {
  data() {
    return {
      path: '/forwards',
      debounceTimeout: null,
      loading: false,
      printing: false,
      idx: null,
      status,
      filter: {
        status: '',
        search: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => this.load(), 600);
      },
      deep: true,
    },
  },
  computed: {
    canAccessPrintForward() {
      if (PRINT_ORDER) {
        return this.$can(PRINT_ORDER);
      }
      return true;
    },
    canAccessRemoveForward() {
      if (DELETE_ORDER) {
        return this.$can(DELETE_ORDER);
      }
      return true;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      this.loading = true;

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.status) {
        params.offset = 0;
        params.status = this.filter.status;
      }

      if (this.filter.search.trim().length > 0) {
        params.offset = 0;
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    create() {
      this.$router.push(`${this.path}/create`);
    },
    deleteItem(id, i) {
      if (!this.canAccessRemoveForward) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este encaminhamento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: close => this.$http
              .delete(`${this.path}/${id}`)
              .then(() => {
                this.data.items.splice(i, 1);
                this.data.total -= 1;
              })
              .catch((e) => {
                if (e.response
                  && e.response.data
                  && e.response.data.message) {
                  this.$toast.show(e.response.data.message, { type: 'error' });
                }
              })
              .then(() => {
                close();
              }),
          },
        ],
      });
    },
    editLink(item) {
      return `${this.path}/${item.id}/edit`;
    },
    print(id, i) {
      if (!this.canAccessPrintForward) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return false;
      }
      this.idx = i;
      this.printing = true;

      return this.$file
        .print(`/forwards/${id}/print`)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    paginate({ offset }) {
      this.data.offset = offset;
      this.load();
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .forwards {
  }
</style>
